import { FornecedorProps } from '../../protocols/FornecedorProps';
import { PerfilProps } from '../../protocols/PerfilProps';
import { SelectProps } from '../../protocols/SelectProps';

export function defineTributacaoOutrasOperacoes(
  perfil: PerfilProps,
  fornecedor: FornecedorProps,
  tributacoes: SelectProps[],
  cod_tributacao: number,
  cod_tributacao_entrada: number,
  tipo_tributacao: number,
): any {
  if (perfil?.tipo_operacao === 0) {
    if (
      fornecedor?.cod_trib_simples !== 0 &&
      fornecedor?.flg_optante_simples === true &&
      (tipo_tributacao === 0 || tipo_tributacao === 2)
    ) {
      const tributacao = tributacoes.find(
        (item) => item.value === fornecedor?.cod_trib_simples,
      );
      return tributacao;
    }
    const tributacao = tributacoes.find(
      (item) => item.value === cod_tributacao_entrada,
    );
    return tributacao;
  }
  const tributacao = tributacoes.find((item) => item.value === cod_tributacao);
  return tributacao;
}
