import React from 'react';
import { NFOutrasOperacoesContextProvider } from './NFOutrasOperacoesContext';
import { NFOutrasOperacoesContent } from './NFOutrasOperacoesContent';
import { TransmissaoProvider } from '~/components/Nfe/TransmissaoNFe/Transmissao';

export const NFOutrasOperacoes: React.FC = () => {
  return (
    <TransmissaoProvider>
      <NFOutrasOperacoesContextProvider>
        <NFOutrasOperacoesContent />
      </NFOutrasOperacoesContextProvider>
    </TransmissaoProvider>
  );
};
