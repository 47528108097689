import { LojaProps } from '../../protocols/LojaProps';
import { CFOPSProps } from '../../protocols/CFOPSProps';
import { FornecedorProps } from '../../protocols/FornecedorProps';

export function defineCfopOutrasOperacoes(
  loja: LojaProps,
  fornecedor: FornecedorProps,
  tipo_tributacao: number | undefined,
  cfops: CFOPSProps[],
) {
  let filtered;
  if (loja.uf === fornecedor?.des_uf) {
    filtered = cfops?.filter(
      (cfop) =>
        cfop.cfop.toString().startsWith('1') ||
        cfop.cfop.toString().startsWith('5'),
    );
  } else {
    filtered = cfops?.filter(
      (cfop) =>
        cfop.cfop.toString().startsWith('2') ||
        cfop.cfop.toString().startsWith('3') ||
        cfop.cfop.toString().startsWith('6') ||
        cfop.cfop.toString().startsWith('7'),
    );
  }
  if (tipo_tributacao === 3) {
    filtered = filtered?.filter((cfop) => {
      return cfop.flg_separa_subst === true || cfop.flg_dif === true;
    });
  } else {
    filtered = filtered?.filter((cfop) => {
      return !cfop.flg_separa_subst;
    });
  }

  if (filtered.length >= 1) {
    if (tipo_tributacao === 3) {
      const hasRegSeparaSubst = filtered.find(
        (filter) => filter.flg_separa_subst,
      );
      if (hasRegSeparaSubst) {
        filtered = hasRegSeparaSubst;
      } else {
        const hasRegDif = filtered.find((filter) => filter.flg_dif);
        if (hasRegDif) {
          filtered = hasRegDif;
        }
      }
    } else {
      const hasRegSeparaSubst = filtered.find(
        (filter) => !filter.flg_separa_subst,
      );
      if (hasRegSeparaSubst) {
        filtered = hasRegSeparaSubst;
      } else {
        const hasRegDif = filtered.find((filter) => filter.flg_dif);
        if (hasRegDif) {
          filtered = hasRegDif;
        }
      }
    }
    return Array.isArray(filtered) ? filtered[0] : filtered;
  }
  return cfops[0];
}
