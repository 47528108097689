/* eslint-disable no-restricted-syntax */
import { ItensProps } from '../../protocols/ItensProps';

export function procedureValidationItem(
  item: ItensProps,
  flg_nao_pis_cofins: boolean,
  series: any,
): ItensProps {
  // DEVOLUCAO DE COMPRA PARA COMERCIALIZACAO EM OPERACAO COM MERCADORIA SUJEITA AO REGIME DE SUBSTITUICAO TRIBUTARIA
  if (Number(item.cfop) === 6411) {
    item.val_tabela_liq = 0;
    item.val_total = 0;
    item.val_bc_icms = 0;
    item.val_base_pis = 0;
    item.val_base_cofins = 0;
    item.val_isento_pis_cof = 0;
    item.val_aliq_zero = 0;
    item.val_monofasico = 0;
    item.val_substituicao = 0;
    item.val_nao_tribut = 0;
    item.val_monofasico = 0;
    // itens[index].per_pis = 0;
    // itens[index].per_cofins = 0;
    item.val_pis = 0;
    item.val_ipi = 0;
    item.val_cofins = 0;
    item.val_cofins = 0;
    item.tipo_tributacao = -1;
    item.val_isento_pis_cof = 0;
  }

  if (flg_nao_pis_cofins) {
    // console.log(series);
  }
  return item;
}
