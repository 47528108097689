/* eslint-disable no-lonely-if */
/* eslint-disable prefer-const */
export function procedureCalculaPISCofins(
  flg_nao_pis_cofins_ncm = false,
  flg_nao_pis_cofins_produto = false,
  flg_nao_transf_pis_cofins_fornecedor = false,
  flg_st_bc_pis_cof_parametro_loja = false,
  flg_ipi_bc_pis_cof_parametro_loja = false,
  tipo_nao_pis_cofins_produto: number,
  val_total_nf_item: number,
  val_icms_st_item: number,
  val_ipi_item: number,
  per_pis_item: number,
  per_confins_item: number,
) {
  let val_base_pis = 0;
  let val_base_cofins = 0;
  let val_pis = 0;
  let val_cofins = 0;
  let val_nao_tribut = 0;
  let val_isento_pis_cof = 0;
  let val_aliq_zero = 0;
  let val_monofasico = 0;
  let val_substituicao = 0;
  let val_imune = 0;
  let val_suspensao = 0;

  // Alimenta campos referentes ao PIS/Cofins
  if (
    flg_nao_pis_cofins_ncm === false &&
    flg_nao_pis_cofins_produto === false &&
    flg_nao_transf_pis_cofins_fornecedor === false
  ) {
    // Considerar o ICMS-ST e IPI na BC Crédito Pis/Cofins Regra de Empresa)
    if (flg_st_bc_pis_cof_parametro_loja && flg_ipi_bc_pis_cof_parametro_loja) {
      val_base_pis = val_total_nf_item;
      val_base_cofins = val_total_nf_item;
      val_pis = val_total_nf_item * (per_pis_item / 100);
      val_cofins = val_total_nf_item * (per_confins_item / 100);
    }
    // Não considerar ST nem IPI na base
    if (
      !flg_st_bc_pis_cof_parametro_loja &&
      !flg_ipi_bc_pis_cof_parametro_loja
    ) {
      val_base_pis = val_total_nf_item - val_icms_st_item - val_ipi_item;
      val_base_cofins = val_total_nf_item - val_icms_st_item - val_ipi_item;
      val_pis =
        (val_total_nf_item - val_icms_st_item - val_ipi_item) *
        (per_pis_item / 100);
      val_cofins =
        (val_total_nf_item - val_icms_st_item - val_ipi_item) *
        (per_confins_item / 100);
    }
    // Não considerar apenas ST
    if (
      !flg_st_bc_pis_cof_parametro_loja &&
      flg_ipi_bc_pis_cof_parametro_loja
    ) {
      val_base_pis = val_total_nf_item - val_icms_st_item;
      val_base_cofins = val_total_nf_item - val_icms_st_item;
      val_pis = (val_total_nf_item - val_icms_st_item) * (per_pis_item / 100);
      val_cofins =
        (val_total_nf_item - val_icms_st_item) * (per_confins_item / 100);
    }
    // Não considerar apenas IPI
    if (
      flg_st_bc_pis_cof_parametro_loja &&
      !flg_ipi_bc_pis_cof_parametro_loja
    ) {
      val_base_pis = val_total_nf_item - val_ipi_item;
      val_base_cofins = val_total_nf_item - val_ipi_item;
      val_pis = (val_total_nf_item - val_ipi_item) * (per_pis_item / 100);
      val_cofins =
        (val_total_nf_item - val_ipi_item) * (per_confins_item / 100);
    }
  } else {
    if (flg_nao_pis_cofins_ncm && flg_nao_transf_pis_cofins_fornecedor) {
      if (
        flg_st_bc_pis_cof_parametro_loja &&
        flg_ipi_bc_pis_cof_parametro_loja
      ) {
        val_nao_tribut = val_total_nf_item;
      }
      if (
        !flg_st_bc_pis_cof_parametro_loja &&
        !flg_ipi_bc_pis_cof_parametro_loja
      ) {
        val_nao_tribut = val_total_nf_item - val_icms_st_item - val_ipi_item;
      }
      if (
        !flg_st_bc_pis_cof_parametro_loja &&
        flg_ipi_bc_pis_cof_parametro_loja
      ) {
        val_nao_tribut = val_total_nf_item - val_icms_st_item;
      }
      if (
        flg_st_bc_pis_cof_parametro_loja &&
        !flg_ipi_bc_pis_cof_parametro_loja
      ) {
        val_nao_tribut = val_total_nf_item - val_ipi_item;
      }
    } else {
      // val_isento_pis_cof
      if (
        flg_st_bc_pis_cof_parametro_loja &&
        flg_ipi_bc_pis_cof_parametro_loja
      ) {
        val_isento_pis_cof = val_total_nf_item;
      }

      if (
        !flg_st_bc_pis_cof_parametro_loja &&
        !flg_ipi_bc_pis_cof_parametro_loja
      ) {
        val_isento_pis_cof =
          val_total_nf_item - val_icms_st_item - val_ipi_item;
      }
      if (
        !flg_st_bc_pis_cof_parametro_loja &&
        flg_ipi_bc_pis_cof_parametro_loja
      ) {
        val_isento_pis_cof = val_total_nf_item - val_icms_st_item;
      }
      if (
        flg_st_bc_pis_cof_parametro_loja &&
        !flg_ipi_bc_pis_cof_parametro_loja
      ) {
        val_isento_pis_cof = val_total_nf_item - val_ipi_item;
      }

      val_base_pis = 0;
      val_base_cofins = 0;
      // Aliquota Zero
      if (tipo_nao_pis_cofins_produto === 0) {
        val_aliq_zero = val_total_nf_item - val_icms_st_item;
      } else {
        val_aliq_zero = 0;
      }
      // Monofasico
      if (tipo_nao_pis_cofins_produto === 1) {
        val_monofasico = val_total_nf_item - val_icms_st_item;
      } else {
        val_monofasico = 0;
      }
      // Substituição
      if (tipo_nao_pis_cofins_produto === 2) {
        val_substituicao = val_total_nf_item - val_icms_st_item;
      } else {
        val_substituicao = 0;
      }
      // Imune
      if (tipo_nao_pis_cofins_produto === 3) {
        val_imune = val_total_nf_item - val_icms_st_item;
      } else {
        val_imune = 0;
      }
      // Suspensão
      if (tipo_nao_pis_cofins_produto === 4) {
        val_suspensao = val_total_nf_item - val_icms_st_item;
      } else {
        val_suspensao = 0;
      }
      val_nao_tribut = 0;
      val_isento_pis_cof = 0;
    }
  }

  return {
    val_base_pis,
    val_base_cofins,
    val_pis,
    val_cofins,
    val_nao_tribut,
    val_isento_pis_cof,
    val_aliq_zero,
    val_monofasico,
    val_substituicao,
    val_imune,
    val_suspensao,
  };
}
