import { transformAsCurrency } from '~/utils/functions';

export function calculaValTabelaLiq(
  qtd_entrada: number | string,
  val_tabela: number | string,
  val_acrescimo: number | string,
  val_desconto: number | string,
  val_frete: number | string,
) {
  const form_qtd_entrada = transformAsCurrency(qtd_entrada);
  const form_val_tabela = transformAsCurrency(val_tabela);
  const form_val_acrescimo = transformAsCurrency(val_acrescimo);
  const form_val_desconto = transformAsCurrency(val_desconto);
  const form_val_frete = transformAsCurrency(val_frete);

  const val_total = form_qtd_entrada * form_val_tabela;
  const val_desconto_total = form_val_desconto;
  const val_acrescimo_total = form_val_acrescimo + form_val_frete;

  // eslint-disable-next-line prettier/prettier
  return (val_total - val_desconto_total) + val_acrescimo_total;
}
