import React, { useContext, useEffect, useState } from 'react';
import FormDefault from '~/components/FormDefault';

import { useNFOutrasOperacao } from './NFOutrasOperacoesContext';
import { Tab, Tabs } from 'react-bootstrap';

import { Nfe } from './screens/Nfe';
import { Container, ContainerLoader } from './styles';
import { Transporte } from './screens/Transporte';
import { Finaceiro } from './screens/Financeiro';
import { Log } from './screens/Log';
import { BuscaNfe } from '~/components/BuscaNfe';
import { TransmitirNFe } from '~/components/TransmitirNFe';
import Search from '~/components/Search';
import { GridRowParams } from '@material-ui/data-grid';
import getLoja from './services/getLoja';
import getFornecedor from './services/gerFornecedor';
import api from '~/services/api';
import moment from 'moment';
import getParcelasFinanceiro from './services/getParcelasFinanceiro';
import getItens from './services/getItens';
import { ClipLoader } from 'react-spinners';
import { ThemeContext } from 'styled-components';
import { formatCurrencyAsText } from '~/utils/functions';
import getLogs from './services/getLogs';
import { createDateWithoutTimezone } from '~/utils/createDateWithoutTimezone ';

export const NFOutrasOperacoesContent: React.FC = () => {
  const [loader, setLoader] = useState(false);
  const [nfManutencao, setNfManutencao] = useState(false);
  const { colors } = useContext(ThemeContext);
  const [nfCancelada, setNfCancelada] = useState<boolean>(false);

  const {
    update,
    setUpdate,
    showSearch,
    setShowSearch,
    onSubmit,
    onClearForm,
    codSeqNf,
    setCodSeqNf,
    onCancel,
    currentTab,
    setCurrentTab,
    importXML,
    numNF,
    perfil,
    itens,
    loja,
    fornecedor,
    disable,
    setValue,
    setValueTransporte,
    setLoja,
    setNumChaveAcesso,
    setFornecedor,
    setPerfil,
    formatPerfil,
    setNumNF,
    setSerieSelect,
    setDtaEmissao,
    setDtaEntrada,
    setItensFinaceiro,
    setItens,
    setIsBlockNf,
    setDisable,
    trasmissao,
    setTransmissao,
  } = useNFOutrasOperacao();

  const onRowClick = async (param: GridRowParams) => {
    const { row } = param;

    const {
      cod_seq_nf,
      num_chave_acesso,
      cod_loja,
      cod_pessoa,
      cod_perfil,
      num_nf,
      num_serie_nf,
      des_especie,
      dta_emissao,
      dta_entrada,
      cod_especie,
      des_doc_fiscal,
      flg_manutencao,
      flg_cancelada,
    } = param.row;

    setLoader(true);
    setUpdate(true);
    setDisable(true);
    setIsBlockNf(true);
    setNfManutencao(flg_manutencao);
    setCodSeqNf(cod_seq_nf);
    setNumChaveAcesso(num_chave_acesso);
    if (num_chave_acesso !== undefined) {
      getLogs({ num_chave_acesso });
    }
    setValue('cod_xml', num_chave_acesso);
    // CAPA NFE
    const Loja = await getLoja(cod_loja);
    // loja
    setValue('cod_loja', cod_loja);
    if (Loja) {
      setLoja(Loja);
    }
    // Parceiro / Fornecedor
    const Fornecedor: any = await getFornecedor(cod_pessoa);
    setFornecedor(Fornecedor);
    setValue('cod_pessoa', Fornecedor);
    setValue('num_especie', {
      value: cod_especie,
      label: `${des_especie} -${des_doc_fiscal}`,
      des_especie,
    });
    // data emissão
    const dateEmissao = moment(createDateWithoutTimezone(dta_emissao)).format(
      'YYYY-MM-DD',
    );
    setDtaEmissao(dateEmissao);
    setValue('dta_emissao', dateEmissao);

    // data entrada
    const dateEntrada = moment(createDateWithoutTimezone(dta_entrada)).format(
      'YYYY-MM-DD',
    );
    setDtaEntrada(dateEntrada);
    setValue('dta_entrada', dateEntrada);
    // ABA Transporte
    setValueTransporte('val_seguro', formatCurrencyAsText(row.val_seguro));
    setValueTransporte(
      'qtd_embalagem',
      formatCurrencyAsText(row.qtd_embalagem),
    );
    setValueTransporte('qtd_peso_liq', formatCurrencyAsText(row.qtd_peso_liq));
    setValueTransporte(
      'qtd_peso_bruto',
      formatCurrencyAsText(row.qtd_peso_bruto),
    );
    setValueTransporte('des_marca_veiculo', row.des_marca_veiculo);
    setValueTransporte('des_placa_veiculo', row.des_placa_veiculo);
    setValueTransporte('des_obs_fiscal', row.des_obs_fiscal);
    // Aba Financeira
    const parcelas = await getParcelasFinanceiro(cod_seq_nf);
    setItensFinaceiro(parcelas);

    const item: any = await getItens(cod_seq_nf);
    setItens(item);

    // Perfil
    const perfilDocumento = await api.get(`/perfil-nf-outras-operacoes/`, {
      params: {
        cod_loja,
        cod_perfil,
      },
    });

    const perfilFinal = await formatPerfil(perfilDocumento.data.data);

    setPerfil(perfilFinal);
    setValue('cod_perfil', {
      value: perfilFinal.value,
      label: perfilFinal.label,
    });
    // num_serie_nf
    setSerieSelect(num_serie_nf);
    perfilFinal.tipo_emissao === 1
      ? setValue('num_serie', {
          value: num_serie_nf,
          label: num_serie_nf,
        })
      : setValue('num_serie', num_serie_nf);
    // num nf
    setNumNF(num_nf);
    setValue('num_nf', num_nf);

    if (perfilFinal.tipo_emissao === 1) {
      setNfCancelada(flg_cancelada);
      if (cod_seq_nf !== undefined) setTransmissao(true);
    }
    setShowSearch(false);
    setLoader(false);
  };
  if (loader) {
    return (
      <ContainerLoader>
        <ClipLoader color={colors.primary} />
      </ContainerLoader>
    );
  }

  return (
    <Container>
      {showSearch && (
        <Search
          newData={() => setShowSearch(false)}
          onRowClick={onRowClick}
          codTela={172}
        />
      )}
      {!showSearch && (
        <FormDefault
          codTela={172}
          title="Lan. NF de Outras Operações"
          codigoRegistro={[{ value: codSeqNf, des_campo: 'Código' }]}
          onSave={onSubmit}
          onCancel={() => {
            onClearForm();
            setNfManutencao(false);
            setShowSearch(true);
          }}
          onClearFields={() => {
            setNfManutencao(false);
            onClearForm();
          }}
          isUpdate={update}
          onNew={() => {
            setNfManutencao(false);
            onClearForm();
          }}
          isSave={disable}
          isDelete={false}
          isDisabledSave={disable}
          onDelete={() => false}
          onReturnSearch={() => {
            onClearForm();
            setNfManutencao(false);
            setShowSearch(true);
          }}
          showSwitch={false}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div>
              {nfCancelada && (
                <h3
                  style={{
                    color: 'red',
                    fontSize: '1.625rem',
                    padding: '0.3125rem',
                    lineHeight: '2.4375rem',
                    borderRadius: '0.3125rem',
                    backgroundColor: 'yellow',
                    marginLeft: '0.3rem',
                    marginTop: '0.3rem',
                  }}
                >
                  CANCELADA
                </h3>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              {trasmissao ? (
                <TransmitirNFe codSeqNf={codSeqNf} />
              ) : (
                !update && (
                  <BuscaNfe
                    codLoja={loja?.value}
                    fornecedorOption={fornecedor}
                    quantityItems={itens.length}
                    numNF={Number(numNF)}
                    setNfData={importXML}
                    reset={onClearForm}
                  />
                )
              )}
              {nfManutencao && (
                <h3
                  style={{
                    color: 'green',
                    fontSize: '1.625rem',
                    padding: '0.3125rem',
                    lineHeight: '2.4375rem',
                    borderRadius: '0.3125rem',
                    backgroundColor: 'yellow',
                    marginLeft: '0.3rem',
                    marginTop: '0.3rem',
                  }}
                >
                  MANUT. FISCAL
                </h3>
              )}
            </div>
          </div>
          <Tabs
            id="controlled-NF"
            activeKey={currentTab}
            onSelect={(tab) => setCurrentTab(tab || 'nfe')}
          >
            <Tab eventKey="nfe" title="NF-e">
              <Nfe />
            </Tab>
            <Tab eventKey="Transporte" title="Transporte/Obs">
              <Transporte />
            </Tab>
            <Tab eventKey="financeiro" title="Financeiro">
              <Finaceiro />
            </Tab>
            <Tab eventKey="log" title="Log">
              <Log />
            </Tab>
          </Tabs>
        </FormDefault>
      )}
    </Container>
  );
};
